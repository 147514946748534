//created by Peggy on 2021/6/21
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { Button, Divider, Form, Input, message, Radio, Select, Tooltip } from 'antd'

import HomeLayout from '@/components/layouts/HomeLayout'

import Modal from '@/components/Modal/Info'
import Warning from '@/components/Modal/Warning'
import { useModel } from 'use-reaction'
import { user } from '@/model/user'

import { checkPurchased, couponCheck, eventMakeOrder } from '@/api/consultation-api'
import { getUserInfo } from '@/api/sign-api'

import icon_grant from '@/images/icons/icon_grant.svg'

import '@/styles/home/checkout.less'
import styled from 'styled-components'
import { eventGet } from '@/api/event-api'
import { localStorageSet } from '@/utils/storage'
import AmountInput from '@/components/Input/AmountInput'
import { InfoCircleFilled } from '@ant-design/icons'
import { view } from '@/model/view'
import { TheField } from '@/components/GoogleTranslate'
import IsThisCorrect from '../../donation/IsThisIncorrect'
const ConsultationContainer = styled.div`
  h3 {
    margin-bottom: 24px;
    input {
      width: 150px;
      padding-left: 6px;
    }
  }
  form {
    h2 {
      margin-bottom: 24px;
    }
    .quantity-wrap {
      &:not(:last-child) {
        margin-bottom: 24px;
      }
      .column {
        flex: 2;
        span:last-child {
          font-size: 12px;
          color: #797f84;
          margin-top: 8px;
        }
      }
      .select-item {
        flex: 3;
        margin-bottom: 0;
      }
    }
  }
`

const EventPurchase = () => {
  const history = useHistory()
  const { eventId } = useParams() as any
  const [form] = Form.useForm()
  const {
    store: { token, info }
  } = useModel(user)

  const {
    store: { isMobile }
  } = useModel(view)
  const [custom, setCustom] = useState<any[]>([])
  const [amount, setAmount] = useState(0)
  const [addon, setAddon] = useState<any>({
    fieldAmount: '',
    contribution: false
  })
  const [flag, setFlag] = useState(false)
  const [warning, setWarning] = useState(false)

  const [eventInfo, setEventInfo] = useState<any>()

  const [existPts, setExistPts] = useState<any>()

  const onFinish = async (values: any) => {
    let { message: ms, participants } = values
    if (!participants?.length) {
      message.error('please select a quantity')
      return false
    }
    // check if partipants has purchased this event
    if (!existPts) {
      const res = await checkPurchased({
        id: +eventId,
        participants: participants.map((_: any) => _.email)
      })
      if (res?.exists?.length) {
        setExistPts(res.exists)
        return false
      }
    }
    let _data = {
      eventId: eventId - 0,
      message: ms,
      participants: participants.map((item: any) => {
        item.customTierName = item.type
        item.price =
          eventInfo.model !== 'pay_as_you_wish'
            ? eventInfo.model === 'custom_tier'
              ? item.price
              : Object.values(eventInfo.price || {}).reduce((sum: number, i: any) => sum + i, 0)
            : eventInfo.price
        delete item.type
        return item
      })
    }
    const price =
      eventInfo?.model === 'custom_tier'
        ? custom
            ?.map((item: any) => (item?.quantity || 0) * item.price)
            .reduce((sum: number, i: any) => sum + i, 0)
        : (eventInfo?.price || 0) * (eventInfo?.quantity || 1)

    // if (eventInfo?.model === "free" || price === 0) {
    //   eventMakeOrder(_data).then(() => {
    //     history.push(`/checkout/success?source=event`);
    //   });
    //   return false;
    // }
    if (amount <= 0) {
      eventMakeOrder(_data).then(() => {
        history.push(`/checkout/success?source=event`)
      })
      return false
    }
    localStorageSet('checkout-event', {
      name: eventInfo.name,
      type: eventInfo.type,
      model: eventInfo?.model,
      ticketModel: eventInfo?.ticketModel,
      eligibleUserRole: eventInfo?.eligibleUserRole,
      price,
      quantity: (custom?.length && custom) || values.quantity || 1,
      data: _data,
      addon
    })
    history.push(`/checkout/${eventId}/pay`)
  }

  const onFinishFailed = (e: any) => {
    message.error(e.errorFields[0].errors[0])
  }

  const [initEvent, setInitEvent] = useState<any>()
  const [initEmail, setInitEmail] = useState('')
  const init = async () => {
    let data = await eventGet(eventId)
    if (data?.pricing?.model === 'pay_as_you_wish' && data?.ticketModel === 'single') {
      if (!token) {
        if (!data?.eligibleUserRole?.includes('guest')) {
          setFlag(true)
          return false
        }
      } else {
        let { roles, sponsorshipValid } = await getUserInfo()
        let _flag = 0
        if (roles?.includes('donor') && !roles.includes('artist')) {
          if (!data?.eligibleUserRole?.includes('donor')) {
            _flag = 1
          }
        } else if (roles?.includes('artist') && !sponsorshipValid) {
          if (!data?.eligibleUserRole?.includes('free_artist')) {
            _flag = 1
          }
        } else if (roles?.includes('artist') && sponsorshipValid) {
          if (!data?.eligibleUserRole?.includes('sponsored_artist')) {
            _flag = 1
          }
        }
        if (_flag === 1) {
          setWarning(true)
          return false
        }
      }

      localStorageSet('checkout-event', {
        name: data?.name,
        type: data?.type,
        model: data?.pricing?.model,
        ticketModel: data?.ticketModel,
        eligibleUserRole: data?.eligibleUserRole,
        price: 0,
        quantity: 1,
        minAmount: data?.pricing?.asYouWishPricing?.minimum?.replace(/,/g, ''),
        maxAmount: data?.pricing?.asYouWishPricing?.maximum?.replace(/,/g, ''),
        data: {
          eventId: eventId - 0
        }
      })
      // history.push(`/checkout/${eventId}/pay`);
      // return false;
    }
    data?.pricing?.model === 'pay_as_you_wish' &&
      setInitEvent({
        name: data?.name,
        model: data?.pricing?.model,
        ticketModel: data?.ticketModel,
        eligibleUserRole: data?.eligibleUserRole,
        price: 0,
        quantity: 1,
        minAmount: data?.pricing?.asYouWishPricing?.minimum?.replace(/,/g, ''),
        maxAmount: data?.pricing?.asYouWishPricing?.maximum?.replace(/,/g, ''),
        data: {
          eventId: eventId - 0
        }
      })
    setCustom(
      data?.pricing?.customTierPricing
        ?.filter((item: any) => item.remaining)
        .map((item: any) => {
          item.quantity = 0
          return item
        }) || []
    )
    let _inventory =
      data?.ticketModel === 'multiple'
        ? data?.pricing?.model !== 'custom_tier'
          ? data?.pricing?.inventory > -1
            ? data?.pricing?.remaining
            : 10000
          : custom?.reduce((sum: number, item: any) => sum + item?.remaining, 0)
        : 1

    let roleInventory: any = {
      sponsored_artist: 0,
      free_artist: 0,
      donor: 0,
      guest: 0
    }
    let _price = 0,
      customPrice: any = {}
    data?.pricing?.model === 'role_tier' &&
      data?.pricing?.roleTierPricing?.map((item: any) => (roleInventory[item?.role] = item?.price))
    data?.pricing?.model === 'custom_tier' &&
      custom?.map((item: any) =>
        Object.assign(customPrice, {
          [item?.name]: item?.price
        })
      )

    let initEmail = ''

    if (!token) {
      if (!data?.eligibleUserRole?.includes('guest')) {
        setFlag(true)
        return false
      }
      data?.pricing?.model === 'role_tier' && !(_price = roleInventory['guest']) && (_inventory = 0)
    } else {
      let { roles, email, sponsorshipValid } = await getUserInfo()
      initEmail = email
      setInitEmail(email)
      if (roles?.includes('donor') && !roles.includes('artist')) {
        data?.pricing?.model === 'role_tier' &&
          !(_price = roleInventory['donor']) &&
          (_inventory = 0)
        if (!data?.eligibleUserRole?.includes('donor')) {
          setWarning(true)
        }
      } else if (roles?.includes('artist') && !sponsorshipValid) {
        data?.pricing?.model === 'role_tier' &&
          !(_price = roleInventory['free_artist']) &&
          (_inventory = 0)
        if (!data?.eligibleUserRole?.includes('free_artist')) {
          setWarning(true)
        }
      } else if (roles?.includes('artist') && sponsorshipValid) {
        data?.pricing?.model === 'role_tier' &&
          !(_price = roleInventory['sponsored_artist']) &&
          (_inventory = 0)
        if (!data?.eligibleUserRole?.includes('sponsored_artist')) {
          setWarning(true)
        }
      }
    }

    setEventInfo({
      ...data,
      name: data.name,
      model: data?.pricing?.model,
      inventory: _inventory,
      price: data?.pricing?.standardPricing?.price || _price || customPrice || 0,
      ticketModel: data?.ticketModel
    })

    //set default value
    if (data?.pricing?.model === 'custom_tier') {
      // console.log(custom, data?.pricing?.customTierPricing);
      const customQuantity = data?.pricing?.customTierPricing
        .filter((item: any) => item.remaining)
        .map((item: any) => {
          item.quantity = undefined
          return item
        })
      form.setFieldsValue({
        customQuantity,
        participants: [
          {
            email: initEmail,
            firstName: info.firstName,
            lastName: info.lastName
          }
        ]
      })
    } else {
      if (_inventory > 0) {
        form.setFieldsValue({
          quantity: 1,
          participants: [
            {
              price: data?.pricing?.standardPricing?.price || _price || customPrice || 0,
              type: data?.pricing?.model,
              firstName: info.firstName,
              lastName: info.lastName,
              email: initEmail
            }
          ]
        })
      }
    }
  }

  useEffect(() => {
    init()
  }, [])

  const handleValues = (e: any, items: any) => {
    if (eventInfo.model === 'custom_tier') {
      if (Object.keys(e)[0] === 'customQuantity') {
        let _temp = JSON.parse(JSON.stringify(items['customQuantity']))
        setCustom(_temp)
      }
    } else {
      if (Object.keys(e)[0] === 'perPrice') {
        setEventInfo({
          ...eventInfo,
          price: (Object.values(e)[0] as string)?.replace(/,/g, '') || 0
        })
      }
      if (Object.keys(e)[0] === 'quantity') {
        setEventInfo({
          ...eventInfo,
          quantity: Object.values(e)[0] || 1
        })
        let _arr = JSON.parse(JSON.stringify(form.getFieldValue('participants') || []))
        let _length = Number(Object.values(e)[0]) - (_arr?.length || 0)
        if (_length > 0) {
          form.setFieldsValue({
            participants: [
              ..._arr,
              ...new Array(_length).fill({
                price: eventInfo.price,
                type: eventInfo.model
              })
            ]
          })
        } else if (_length < 0) {
          _arr.splice(_length, Math.abs(_length))
          form.setFieldsValue({
            participants: _arr
          })
        }
      }
    }
  }

  useEffect(() => {
    let _temp: any[] = []
    const _participants: any[] = JSON.parse(
      JSON.stringify(form.getFieldValue('participants') || [])
    )
    custom?.map((item: any, index) => {
      if (item?.quantity) {
        let i = 0
        while (i < item.quantity) {
          if (_participants && _participants[0]?.type === item.type) {
            _temp.push({
              ..._participants[0],
              price: item.price,
              type: item.name
            })
            _participants?.shift()
          } else {
            _temp.push({
              price: item.price,
              type: item.name
            })
          }
          i++
        }
      }
    })

    if (_temp.length) {
      _temp[0].email = initEmail
    }
    form.setFieldsValue({
      participants: _temp
    })
  }, [custom])

  useEffect(() => {
    const val =
      eventInfo?.model === 'custom_tier'
        ? custom
            ?.map((item: any, index: number) => (item?.quantity || 0) * item.price)
            .reduce((sum: number, i: any) => sum + i, 0)
        : (parseFloat(eventInfo?.price) || 0) * (eventInfo?.quantity || 1)
    setAmount(parseFloat(addon.fieldAmount || '0') + val)
  }, [addon.fieldAmount, eventInfo?.price, eventInfo?.quantity, custom])

  return (
    <HomeLayout style={{ backgroundColor: '#f1f1f6' }}>
      <ConsultationContainer className="consultation-container">
        <h1>
          {!(
            eventInfo?.model === 'free' ||
            (eventInfo?.model === 'role_tier' && +eventInfo?.price == 0)
          )
            ? 'Check out'
            : 'Confirmation'}{' '}
        </h1>
        <h2>
          You're{' '}
          {!(
            eventInfo?.model === 'free' ||
            (eventInfo?.model === 'role_tier' && +eventInfo?.price == 0)
          )
            ? 'purchasing a ticket to'
            : 'registering for'}{' '}
          {eventInfo?.name}
        </h2>
        {eventInfo?.type === 'Development Events' && (
          <div className="dev-evt-tip">
            This event benefits <TheField/> and our programs and services for independent artists. 
            Please join us at the benefit ticket level that feels right for you! 
            If you are unable to attend but would like to support our work, <a target='_blank' href='https://app.thefield.org/contribute-to-the-field' rel="noreferrer">please consider making a tax-deductible donation here.</a>
          </div>
        )}
        <div className="flex align-start">
          <div className="card-wrap left-wrap">
            <Form
              layout="vertical"
              size="large"
              name="basic"
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              onValuesChange={(e, all) => handleValues(e, all)}
            >
              {(eventInfo?.model === 'pay_as_you_wish' && (
                <>
                  <h2>Amount</h2>
                  <Form.Item
                    label="Pay as you wish Amount per ticket"
                    name="perPrice"
                    rules={[
                      {
                        required: true,
                        message: 'Enter price'
                      },
                      {
                        validator: (_, value) => {
                          if (value >= Number(initEvent?.minAmount)) {
                            return Promise.resolve()
                          } else {
                            return Promise.reject(
                              new Error(
                                `The minimum accepted value is $${initEvent?.minAmount}. Please try again`
                              )
                            )
                          }
                        }
                      }
                    ]}
                  >
                    <AmountInput
                      placeholder={`The minimum accepted value is $${initEvent?.minAmount}`}
                    />
                  </Form.Item>
                </>
              )) || <h2>Quantity</h2>}

              {eventInfo?.model === 'custom_tier' ? (
                <Form.List name="customQuantity">
                  {(fields) =>
                    fields?.map((field: any) => (
                      <div className="flex quantity-wrap" key={field.key}>
                        <div className="flex column align-start">
                          <span>
                            <b>{custom[field.key]?.name}: </b>${custom[field.key]?.price}
                          </span>
                          <span>
                            {custom[field.key]?.remaining > -1
                              ? custom[field.key]?.remaining
                              : 10000}{' '}
                            tickets left
                          </span>
                        </div>
                        <Form.Item
                          label="Quantity"
                          name={[field?.name, 'quantity']}
                          fieldKey={[field?.fieldKey, 'quantity']}
                          className="select-item"
                          rules={[
                            {
                              required: false,
                              whitespace: false,
                              type: 'number',
                              message: 'Select quantity'
                            }
                          ]}
                        >
                          <Select
                            allowClear
                            size="large"
                            placeholder="Select quantity"
                            disabled={
                              form.getFieldValue('participants')?.length &&
                              !custom[field.key]?.quantity &&
                              eventInfo.ticketModel === 'single' &&
                              eventInfo?.model !== 'custom_tier'
                            }
                          >
                            {new Array(
                              eventInfo.ticketModel === 'single' ? 1 : custom[field.key]?.remaining
                            )
                              .fill(1)
                              ?.map((_, index) => (
                                <Select.Option value={index + 1} key={index}>
                                  {index + 1}
                                </Select.Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </div>
                    ))
                  }
                </Form.List>
              ) : (
                <Form.Item
                  label="Quantity"
                  name="quantity"
                  className="select-item"
                  rules={[
                    {
                      required: true,
                      whitespace: false,
                      type: 'number',
                      message: 'Select quantity'
                    }
                  ]}
                >
                  <Select size="large" placeholder="Select quantity">
                    {eventInfo?.inventory > 0 &&
                      new Array(eventInfo?.inventory).fill(1)?.map((_, index) => (
                        <Select.Option value={index + 1} key={index}>
                          {index + 1}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              )}

              <Divider />
              <div className="flex column start align-start">
                <h2>
                  Contribute to <TheField/>
                  <Tooltip title="Contributions to The Field help us serve and support independent performing artists and their companies. Thank you!">
                    <InfoCircleFilled style={{ fontSize: 16, color: '#696969', marginLeft: 4 }} />
                  </Tooltip>
                </h2>
                <Radio.Group
                  value={addon.contribution}
                  className="frequency-radio"
                  size="large"
                  onChange={(e) => {
                    setAddon({
                      ...addon,
                      fieldAmount: '',
                      contribution: e.target.value
                    })
                  }}
                >
                  <Radio.Button value={false}>No, thanks</Radio.Button>
                  <Radio.Button value={true}>Yes, I’d love to</Radio.Button>
                </Radio.Group>

                {addon.contribution && (
                  <div className="field-form-items">
                    <span>Amount </span>
                    <Input
                      size="large"
                      placeholder="Enter amount"
                      prefix="$"
                      value={addon.fieldAmount}
                      onChange={(e) => {
                        const fieldAmount = e.target.value.replace(/[^\d\\.]+/g, '')
                        setAddon({
                          ...addon,
                          fieldAmount
                        })
                      }}
                    />
                  </div>
                )}
              </div>
              <Divider />
              <h2>Participants information</h2>
              <IsThisCorrect/>
              <Form.List name="participants">
                {(fields) =>
                  fields?.map((field: any, index) => (
                    <div key={field.key}>
                      <h3 className="flex start">
                        {(eventInfo?.ticketModel !== 'single' ||
                          eventInfo?.model === 'custom_tier') && (
                          <>
                            {field.key === 0 && 'Primary '} Participant {field.key + 1}{' '}
                          </>
                        )}

                        {eventInfo?.model === 'custom_tier' && (
                          <>
                            {' '}
                            -{' '}
                            <Form.Item
                              noStyle
                              name={[field?.name, 'type']}
                              fieldKey={[field?.fieldKey, 'type']}
                            >
                              <Input bordered={false} />
                            </Form.Item>
                            <Form.Item
                              noStyle
                              name={[field?.name, 'price']}
                              fieldKey={[field?.fieldKey, 'price']}
                            >
                              <Input hidden bordered={false} />
                            </Form.Item>
                          </>
                        )}
                      </h3>
                      <div className={isMobile ? '' : 'flex'}>
                        <Form.Item
                          label="First Name"
                          name={[field?.name, 'firstName']}
                          fieldKey={[field?.fieldKey, 'firstName']}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: 'Enter first Name'
                            }
                          ]}
                        >
                          <Input size="large" placeholder="Enter first Name" />
                        </Form.Item>
                        <Form.Item
                          label="Last Name"
                          name={[field?.name, 'lastName']}
                          fieldKey={[field?.fieldKey, 'lastName']}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: 'Enter last Name'
                            }
                          ]}
                        >
                          <Input size="large" placeholder="Enter last Name" />
                        </Form.Item>
                      </div>
                      <Form.Item
                        label={
                          <div>
                            Email address
                            {initEmail && (
                              <Tooltip title="This is the email address associated with your account. We'll send information and updates about the event here">
                                <InfoCircleFilled style={{ marginLeft: 4 }} />
                              </Tooltip>
                            )}
                          </div>
                        }
                        name={[field?.name, 'email']}
                        fieldKey={[field?.fieldKey, 'email']}
                        rules={[
                          {
                            required: !initEmail,
                            type: 'email',
                            message: 'Enter email address'
                          }
                        ]}
                      >
                        <Input
                          style={{ color: '#000' }}
                          disabled={field.key === 0 && !!token && !!initEmail}
                          size="large"
                          placeholder="Enter Email address"
                        />
                      </Form.Item>
                    </div>
                  ))
                }
              </Form.List>
              <Form.Item
                className="textarea-wrap"
                label="Message to event Organizer"
                name="message"
              >
                <Input.TextArea placeholder="Enter a message" />
              </Form.Item>
            </Form>
          </div>
          <div className="card-wrap right-wrap">
            <h3>Order summary</h3>
            <p className="flex">
              <span>Event</span>
              {eventInfo?.name}
            </p>
            {!(
              eventInfo?.model === 'free' ||
              (eventInfo?.model === 'role_tier' && +eventInfo?.price == 0)
            ) && (
              <p className="flex">
                <span>Price</span>
                {Number(
                  eventInfo?.model === 'custom_tier'
                    ? custom
                        ?.map(
                          (item: any, index: number) => (item?.quantity || 0) * (item.price || 0)
                        )
                        .reduce((sum: number, i: any) => sum + i, 0)
                    : (parseFloat(eventInfo?.price) || 0) * (eventInfo?.quantity || 1)
                ).toLocaleString('en', {
                  style: 'currency',
                  currency: 'USD'
                })}
              </p>
            )}
            <p className="flex align-start">
              <span>Quantity</span>
              <span className="flex column align-end">
                {eventInfo?.inventory
                  ? (eventInfo?.model === 'custom_tier' &&
                      custom?.map((item: any, index: number) => (
                        <i key={item.name} className="flex">
                          {item?.quantity || 0} {item.name}
                        </i>
                      ))) ||
                    form.getFieldValue('quantity') ||
                    1
                  : 0}
              </span>
            </p>
            {addon.fieldAmount && (
              <p className="flex">
                <span>Contribute to <TheField/></span>$
                {addon?.fieldAmount?.toLocaleString('en', {
                  style: 'currency',
                  currency: 'USD'
                })}
              </p>
            )}
            {!(
              eventInfo?.model === 'free' ||
              (eventInfo?.model === 'role_tier' && +eventInfo?.price == 0)
            ) && (
              <>
                <Divider />
                <p className="flex">
                  Total amount
                  <b>
                    {Number(amount).toLocaleString('en', {
                      style: 'currency',
                      currency: 'USD'
                    })}
                  </b>
                </p>
              </>
            )}

            <Divider />
            <p className="tips">
              By confirming{' '}
              {!(
                eventInfo?.model === 'free' ||
                (eventInfo?.model === 'role_tier' && +eventInfo?.price == 0)
              )
                ? 'this payment'
                : 'register'}
              , you agree to our{' '}
              <a rel="noreferrer" target="_blank" href="https://thefield.org/terms-of-service/">
                TERMS OF SERVICE
              </a>
            </p>

            <Button type="primary" block onClick={() => form.submit()}>
              {!(
                eventInfo?.model === 'free' ||
                (eventInfo?.model === 'role_tier' && +eventInfo?.price == 0)
              )
                ? 'next'
                : 'register'}
            </Button>
          </div>
        </div>
      </ConsultationContainer>
      <Modal
        visible={flag}
        width={420}
        footer={[
          <Button key="back" onClick={() => history.go(-1)}>
            LATER
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => history.push(`/sign/in?to=${history.location.pathname}`)}
          >
            Sign in
          </Button>
        ]}
        img={icon_grant}
      >
        <h2>Sign in to check out</h2>
        <p>
          Please log in to complete this order. The price may vary depending on your membership
          plan.
        </p>
      </Modal>
      <Modal
        visible={!!existPts?.length}
        width={420}
        footer={[
          <Button key="back" onClick={() => history.go(-1)}>
            cancel
          </Button>,
          <Button
            key="submit-go"
            type="primary"
            onClick={() => {
              setExistPts([])
              form.submit()
            }}
          >
            yes
          </Button>
        ]}
      >
        <h2>Oops</h2>
        <p>
          Looks like <i style={{ fontWeight: 'bold' }}>{existPts?.join(',')}</i> already registered
          for this event. Would you like to proceed?
        </p>
      </Modal>
      <Warning
        visible={warning}
        onClose={() => history.push('/explore-artist-community-events')}
        title="Registrations warning"
        content="You are not eligible to register for this event, please contact support@thefield.org for further assistance."
      />
    </HomeLayout>
  )
}

export default EventPurchase
